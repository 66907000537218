import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85c11d8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid m-0 justify-content-center" }
const _hoisted_2 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_3 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesOppsDataTableWrapper = _resolveComponent("SalesOppsDataTableWrapper")!
  const _component_SalesOppDialog = _resolveComponent("SalesOppDialog")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_Button, {
        class: "split-sales-order-btn",
        icon: "pi pi-filter-slash",
        outlined: "",
        onClick: _ctx.clearFilters
      }, null, 8, ["onClick"]), [
        [_directive_tooltip, 'Clear All Filters']
      ]),
      _createVNode(_component_Button, {
        class: "split-sales-order-btn",
        label: "Print/Export",
        icon: "pi pi-print",
        onClick: _ctx.printExportData
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        "data-test": "newSalesOpps",
        label: "New Opportunity",
        icon: "pi pi-plus",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSalesOpp(false)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_SalesOppsDataTableWrapper, {
            ref: "salesOppsTable",
            "customer-index": _ctx.index,
            "current-view": _ctx.currentView,
            onUpdateBadge: _cache[1] || (_cache[1] = (field, value) => _ctx.$emit('update-badge', field, value)),
            maxExportRows: 1000
          }, null, 8, ["customer-index", "current-view"]),
          _createVNode(_component_SalesOppDialog, {
            show: this.showDialog,
            isEditing: this.isEditing,
            saleOpp: this.saleOpp,
            customer: _ctx.getCustomerFields,
            onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hideDialog(_ctx.hidden))),
            onOnSave: _ctx.fetchSaleOpps,
            opportunity: this.saleOpp
          }, null, 8, ["show", "isEditing", "saleOpp", "customer", "onOnSave", "opportunity"])
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ])
  ]))
}